<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业" v-if="userInfo.eGroupCode">
          <l-select v-model="condition.enterpriseCode" :data="userInfo.eGroupChildren" :prop="['enterpriseCode', 'enterpriseName']" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="建议类型">
          <l-select v-model="condition.adviceType" :remote="$api.adviceType"></l-select>
        </el-form-item>
        <el-form-item label="建议分类">
          <l-select v-model="condition.adviceCategory" :remote="$api.adviceCategory"></l-select>
        </el-form-item>
        <el-form-item label="建议状态">
          <l-select v-model="condition.adviceStatus" :data="$constant.adviceStatusList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="实施状态">
          <l-select v-model="condition.effectStatus" :data="$constant.adviceEffectStatusList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="提交部门">
          <l-select v-model="condition.createDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item label="审核部门">
          <l-select v-model="condition.verifyDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item label="实施部门">
          <l-select v-model="condition.effectDepartId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="$constant.advice_dateType"></l-select>
          </template>
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" width="300" placeholder="建议项目/员工名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="adviceTitle" slot-scope="scope">
          <el-button type="text" @click="$site.view_advice_detail(scope.row.adviceId)">{{ scope.row.adviceTitle }}</el-button>
        </template>
        <template slot="adviceStatusName" slot-scope="scope">
          <span v-if="scope.row.adviceStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.adviceStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.adviceStatus == 10" class="text-green">已入围</span>
          <span v-else-if="scope.row.adviceStatus == 9" class="text-red">无效建议</span>
        </template>
        <template slot="effectStatusName" slot-scope="scope">
          <span v-if="scope.row.effectStatus == 0" class="text-grey">---</span>
          <span v-else-if="scope.row.effectStatus == 1" class="text-blue">实施中</span>
          <span v-else-if="scope.row.effectStatus == 2" class="text-green">已完成</span>
          <span v-else-if="scope.row.effectStatus == 8" class="text-red">无法实施</span>
          <span v-else-if="scope.row.effectStatus == 9" class="text-red">暂缓实施</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-tooltip v-if="scope.row.adviceStatus == 1" class="item" effect="dark" content="转移给其他人进行审核" placement="left">
            <el-button type="primary" size="mini" @click="transfer(scope.row)">审核转移</el-button>
          </el-tooltip>
          <el-tooltip v-if="scope.row.adviceStatus == 10 && scope.row.effectStatus == 1" class="item" effect="dark" content="退回重新审核安排实施" placement="left">
            <el-button type="primary" size="mini" @click="reback(scope.row)">实施退回</el-button>
          </el-tooltip>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '建议标题', slot: 'adviceTitle', overflow: true },
          { label: '建议类型', prop: 'adviceType', overflow: true },
          { label: '建议分类', prop: 'adviceCategory' },
          { label: '提交人', prop: 'createUserName' },
          { label: '审核人', prop: 'verifyUserName' },
          { label: '实施人', prop: 'effectUserName' },
          { label: '提交时间', prop: 'submitTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '审核日期', prop: 'verifyTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '实施日期', prop: 'effectTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '建议状态', slot: 'adviceStatusName' },
          { label: '实施状态', slot: 'effectStatusName' },
          { label: '操作', slot: 'operation' }
        ]
      },
      condition: {
        departId: '',
        dateType: 'submitTime',
        startDate: '',
        endDate: '',
        keyword: '',
        adviceStatus: '',
        effectStatus: '',
        adviceCategory: '',
        adviceType: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.condition.enterpriseCode = this.userInfo.enterpriseCode
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/advice/pagedlist', this.condition)
    },
    reback(item) {
      this.$lgh.msgConfirm('确认退回实施,重新审核吗?', () => {
        this.$lgh.post('api/enterprise/advice/reback', item).then((res) => {
          this.getPageData()
        })
      })
    },
    transfer(item) {
      this.$site.sel_enterpriseEmployee(item.enterpriseCode, { userId: item.verifyUserId }).then((user) => {
        this.$lgh.msgConfirm('确认将此建议由[' + item.verifyUserName + ']转移给[' + user.name + ']进行审核吗？', () => {
          var data = Object.assign({}, item)
          data.transferDesc = '管理员[' + this.userInfo.fullName + ']后台操作建议审核转移'
          data.transferDepartId = user.departId
          data.transferDepartName = user.departName
          data.transferUserId = user.userId
          data.transferUserName = user.name
          this.$lgh.post('api/enterprise/advice/verify?type=transfer', data).then((e) => {
            this.$lgh.notifySuccess('审核转移', '已转移给[' + data.transferUserName + ']')
            this.getPageData()
          })
        })
      })
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/advice/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url } }, '点击下载'))
      })
    }
  }
}
</script>
